<template>
  <div class="space-y-3 mt-2">
    <div class="relative flex items-start cursor-pointer" v-for="(option, optionIndex) in question_options" :key="optionIndex">
      <div class="flex items-center">
        <input
          @change="inputChanged"
          v-model="modelValue"
          :id="`${name}-${option.option_value}-${rowIndex}`"
          :value="option.option_value"
          type="radio"
          class="focus:ring-secondary h-4 w-4 text-secondary border-gray-300 cursor-pointer"
        />
        <label :for="`${name}-${option.option_value}-${rowIndex}`" class="pl-3 leading-none text-gray-700 cursor-pointer">{{ option.option_name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forms.Fields.Radio',

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    dynamic_options: {
      type: Array,
      default: function () {
        return []
      }
    },
    rowIndex: {
      type: [Number, null],
      default: null
    }
  },

  data() {
    return {
      changed: false,
      modelValue: [],
      question_options: []
    }
  },

  created() {
    // Create an independent debounce instance for each time this component is created
    this.save = function (e) {
      let value = this.modelValue
      if (value !== undefined && value !== null) {
      if (!e.target.checkValidity()) {
        this.$emit('update:errors', [e.target.validationMessage])
      } else {
        this.$emit('update:errors', [])
      }
        this.$emit('change', value)
      }
    }
    this.modelValue = this.value
  },

  mounted() {
    if (this.$route.query.question && !isNaN(this.$route.query.question) &&
      this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }

    if(this.dynamic_options === null) {
      this.question_options = this.options
    }

    if (this.dynamic_options !== null) {
      for(var i = 0; i < this.dynamic_options.options.length; i++){
        this.question_options.push({
          option_value: this.dynamic_options.options[i].Waarde,
          option_name: this.dynamic_options.options[i].Waarde
        })
      }
    }
  },

  methods: {
    inputChanged(e) {
      let value = this.modelValue
      this.changed = value !== this.value
      if (this.changed) {
        this.$emit('update:value', value)
        this.save(e)
      }
    },

    save() {
      // Placeholder method to be filled in creation of this method
    },
  }
}
</script>
