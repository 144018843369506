<template>
  <div class="w-full shadow-sm rounded-md">
    <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm" v-if="hasPrepend">
      {{ prepend }}
    </span>
    <input
      ref="input"
      type="datetime-local"
      :name="name"
      :id="name"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      @change="inputChanged"
      :class="[
        'input-default mt-1',
        { 'rounded-r-md' : hasPrepend && !hasAppend },
        { 'rounded-l-md' : hasAppend && !hasPrepend },
        { 'rounded-md' : !hasPrepend && !hasAppend },
        { 'border-red-500 focus:ring-red-600 focus:border-red-600' : hasError },
        { 'border-gray-300 focus:ring-secondary focus:border-secondary' : !hasError }
      ]"
    />
    <span class="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm" v-if="hasAppend">
      {{ append }}
    </span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'Forms.Fields.InputDateTime',

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    name: {
      type: [String,Number],
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    default: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      changed: false
    }
  },

  computed: {
    hasPrepend() {
      return this.prepend && this.prepend.length
    },
    hasAppend() {
      return this.append && this.append.length
    },
  },

  created() {
    Number.prototype.padLeft = function(base,chr) {
      var  len = (String(base || 10).length - String(this).length)+1
      return len > 0? new Array(len).join(chr || '0')+this : this
    }

    // Create an independent debounce instance for each time this component is created
    this.save = debounce(function(e) {
      const d = new Date(e.target.value), dateTimeFormatted = [
          d.getFullYear(),
          (d.getMonth()+1).padLeft(),
          d.getDate().padLeft(),
        ].join('-') +' ' + [
          d.getHours().padLeft(),
          d.getMinutes().padLeft()
        ].join(':')
      let value = dateTimeFormatted
      if (value !== undefined && value !== null) {
        if (!e.target.checkValidity()) {
          this.$emit('update:errors', [e.target.validationMessage])
        } else {
          this.$emit('update:errors', [])
        }
        this.$emit('change', value)
      }
    }, 750)
  },

  mounted() {
    if (this.default !== null && this.value === null) {
      this.$emit('update:value', this.default)
      this.$emit('change', this.default)
    }
    if (this.$route.query.question && !isNaN(this.$route.query.question)
      && this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }
  },

  methods: {
    inputChanged(e) {
      let value = e.target.value
      this.changed = value !== this.value
      if (this.changed) {
        this.$emit('update:value', value)
        this.save(e)
      }
    },

    save() {
      // Placeholder method to be filled in creation of this method
    }
  }
}
</script>
