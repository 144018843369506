<template>
  <div>
    <label class="block font-medium text-gray-700">{{ label }}<span v-if="required" class="text-red-600"> *</span></label>
    <p class="block whitespace-pre text-gray-500 text-sm" v-if="description && description.length">{{ description }}</p>
    <div class="mt-0.5">
      <slot></slot>
    </div>
    <template v-if="errors.length">
      <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1" v-for="(error, errorIndex) in errors" :key="errorIndex">
        {{ error }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Crud.Field',

  props: {
    label: String,
    rowIndex: Number || null,
    description: String,
    required: Boolean,
    errors: {
      type: Array,
      default: new Array
    },
  }
}
</script>
