<template>
  <input
    @input="(e) => inputEvent(e)"
    type="text"
    :name="name"
    :value="value"
    :disabled="disabled && !editing"
    :required="required"
    :minlength="minlength"
    :maxlength="maxlength"
    :class="[
      'input-default',
      { 'border-gray-300' : !editing },
      { 'border-red-500 text-red-500' : hasError },
      { 'bg-gray-100 pointer-events-none' : disabled && !editing }
    ]">
</template>

<script>
export default {
  name: 'Crud.Fields.InputText',

  props: {
    name: String,
    value: String,
    editing: Boolean,
    hasError: Boolean,
    disabled: Boolean,
    required: Boolean,
    minlength: Number,
    maxlength: Number,
  },

  emits: ['update:value', 'input'],

  methods: {
    inputEvent(e) {
      this.$emit('update:value', e.target.value)
      this.$emit('input', e.target.value)
    }
  }
}
</script>
