<template>
  <div>
    <div class="flex items-center mt-1" @click.prevent="toggleScanner">
      <InputText :value="value" :disabled="this.disabled" :readonly="true"/>
      <button type="button" v-if="!showScanner" class="btn-secondary h-[46px] w-12 ml-2">
        <i class="fa-solid fa-qrcode"></i>
      </button>
      <button type="button" v-if="showScanner" class="btn-danger h-[46px] w-12 ml-2">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div v-if="codeScanned" class="bg-green-400 border w-full transition-all rounded-lg mt-2">
      <div class="flex justify-center items-center w-full h-full">
        <i class="fas fa-check text-6xl text-white"/>
      </div>
    </div>
    <div v-if="showScanner" class="overflow-hidden w-full h-full fixed sm:relative sm:mt-2 sm:rounded inset-0 z-50 transition-all">
      <div class="w-full h-full">
        <div class="bg-black shadow-lg w-full h-full relative">
          <div class="absolute top-0 right-0 p-2 z-40 sm:hidden ios-notch-top-padding">
            <button type="button" class="btn-danger" @click.prevent="toggleScanner">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="w-full h-full flex justify-center items-center">
            <div id="reader" class="w-full"/>
          </div>
        </div>
      </div>
      <div v-if="scannerLoading">
        <div class="flex h-full w-full lg:w-auto justify-center items-center absolute inset-0 select-none">
          <div class="absolute inset-0 z-40"/>
          <img :src="publicPath + '/img/puff-loader.svg'" width="80" class="relative z-50" />
        </div>
      </div>

      <div class="absolute inset-x-0 bottom-1 text-center" v-show="scannerStatus.length">
        <div class="bg-black/70 px-2 py-1 rounded text-xs text-white/90 font-medium inline-block mx-auto">
          {{ scannerStatus }} <i class="fas fa-spinner-third animate-spin ml-1"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/forms/fields/InputText'
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode"

export default {
  name: 'Forms.Fields.QR',

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: [String,Number],
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  components: {
    InputText
  },

  data() {
    return {
      publicPath: window.location.origin,
      error: '',
      codeScanned: false,
      showScanner: false,
      scanner: null,
      scannerLoading: false,
      showInnerScanner: false,
      scannerStatus: ''
    }
  },

  methods: {
    async toggleScanner() {
      if (this.showScanner === true){
        await this.scanner.stop()
        this.showScanner = false
        return
      }
      this.codeScanned = false
      this.showScanner = true
      setTimeout(() => this.init(), 200)
    },

    init() {
      const formatsToSupport = [
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.EAN_13,
        Html5QrcodeSupportedFormats.EAN_8,
        Html5QrcodeSupportedFormats.CODE_39
      ]

      this.scanner = new Html5Qrcode('reader')
      const cameraConfig = {
        facingMode: 'environment'
      }
      const scannerConfig = {
        aspectRatio: 1,
        formatsToSupport: formatsToSupport
      }
      this.scannerLoading = true
      const vm = this
      this.scanner.start(cameraConfig, scannerConfig, this.qrCodeSuccessCallback, this.qrCodeFailCallback).then(() => {
        vm.scannerLoading = false
        vm.showInnerScanner = true
      })
    },

    async qrCodeSuccessCallback(decodedText) {
      this.scannerStatus = ''
      await this.scanner.stop()
      this.codeScanned = true
      this.showScanner = false
      this.showInnerScanner = false
      setTimeout(() => this.codeScanned = false, 1000)
      this.$emit('update:value', decodedText)
      this.$emit('change', decodedText)
    },

    async qrCodeFailCallback(errorMessage, error) {
      if (process.env.VUE_APP_DEBUG === 'true') {
        console.log(errorMessage, error)
        this.scannerStatus = errorMessage
      } else {
        if (error.type === 0) {
          this.scannerStatus = 'Bezig met zoeken naar QR of barcode'
        } else {
          this.scannerStatus = 'Er is een fout opgetreden'
        }
      }
    }
  }
}
</script>
