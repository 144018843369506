<script setup>
import { computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'
import axios from 'axios'

const props = defineProps({
  sectionId: Number,
  formId: Number,
  title: String,
  required: Boolean,
  id: Number,
  type: Object,
  value: [Object, Array, String],
  restrictions: Array,
  errors: Array,
  submissionData: Object,
  options: Array,
  showEditButton: {
    type: Boolean,
    default: true,
  }
})

const route = useRoute()
const accountStorageURI = process.env.VUE_APP_MS_BLOB_STORAGE_URL

const getRestrictionValueByTypeName = (name, restrictions) => {
  let relatedRestriction = restrictions.filter((restriction) => restriction.type.name === name)
  if (relatedRestriction.length) {
    return relatedRestriction[0][relatedRestriction[0].type.check_field]
  }
}

const parsedValueByQuestionType = computed(() => {
  if (props.value === undefined) {
    return props.value
  }
  let parsedValue = props.value
  if (['bool', 'select', 'radio'].includes(props.type.name)) {
    const relevantOption = props.options.filter((option) => option.option_value == props.value)
    if (relevantOption.length) {
      parsedValue = relevantOption[0].option_name
    }
  }
  if (props.type.name === 'checkbox') {
    let combinedNewValue = []
    const explodedValue = props.value.split(',')
    for (let i = 0; i < explodedValue.length; i++) {
      const valuePart = explodedValue[i]
      const relevantOption = props.options.filter((option) => option.option_value == valuePart)
      if (relevantOption.length) {
        combinedNewValue.push(relevantOption[0].option_name)
      }
    }
    parsedValue = combinedNewValue
  }
  return parsedValue
})

const groupAnswersByRow = (xs, key) => {
  let groupedArray = xs.reduce((rv, x) => {
    let v = key instanceof Function ? key(x) : x[key]
    let el = rv.find((r) => r && r.key === v)
    if (el) {
      el.values.push(x)
    } else {
      rv.push({
          key: v,
          values: [x]
      })
    }
    return rv
  }, [])

  // Sort array of objects by object key
  groupedArray.sort((a, b) => {
    if (a.key < b.key) return -1
    if (a.key > b.key) return 1
    return 0
  })
  return groupedArray
}

function downloadAs(submissionId, fileName, name){
  axios.get(`${this.accountStorageURI}/forms/submissions/${submissionId}/${fileName}`, {
    headers: {
      "Content-Type": "application/octet-stream"
    },
    responseType: "blob",
  })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
}

</script>

<template>
  <div>
    <div class="lg:grid lg:grid-cols-3 lg:gap-4">
      <dt class="text-sm font-medium text-gray-500">
        {{ title }}
        <span class="text-red-500" v-if="required">
          *
        </span>
      </dt>
      <dd class="mt-1 flex justify-between text-sm text-gray-900 lg:mt-0 lg:col-span-2">
        <input
          :name="props.id"
          class="hidden"
          :type="props.type.name"
          :value="props.value"
          :checked="props.value ? true : false"
          :required="props.required"
          :min="getRestrictionValueByTypeName('min', props.restrictions)"
          :max="getRestrictionValueByTypeName('max', props.restrictions)"
          :step="getRestrictionValueByTypeName('step', props.restrictions)"
        />
        <template v-if="value !== undefined && value !== null">
          <div class="break-all w-full" :set="parsedValue = parsedValueByQuestionType">
            <ul v-if="type.name === 'checkbox'">
              <li v-for="(valueOption, valueOptionIndex) in parsedValue" :key="valueOptionIndex">
                - {{ valueOption }}
              </li>
            </ul>
            <span v-else-if="type.name === 'upload' && parsedValue !== undefined">
              <a v-if="parsedValue.originalName" class="text-secondary flex items-center hover:underline focus:underline" @click="downloadAs(submissionData.id, parsedValue.name, parsedValue.originalName)">
                <p v-if="parsedValue.originalName">{{ parsedValue.originalName }}</p>
                <i class="fas fa-file-download icon-replacement-fix-relative ml-1"/>
              </a>
              <a v-else class="text-secondary flex items-center hover:underline focus:underline" @click="downloadAs(submissionData.id, parsedValue.name, parsedValue.name)">
                <p>{{ parsedValue.name }}</p>
                <i class="fas fa-file-download icon-replacement-fix-relative ml-1"/>
              </a>
            </span>
            <span v-else-if="type.name === 'datetime' && parsedValue !== undefined">
              {{ moment(parsedValue).format('DD-MM-YYYY HH:mm') }}
            </span>
            <span v-else-if="type.name === 'date' && parsedValue !== undefined">
              {{ moment(parsedValue).format('DD-MM-YYYY') }}
            </span>
            <span v-else-if="type.name === 'repeater' && parsedValue !== undefined">
              <div class="space-y-3">
                <div v-for="row in groupAnswersByRow(parsedValue, 'row_id')" :key="row.key">
                  <ul class="bg-white p-2 border rounded">
                    <li v-for="rowValues in row.values" :key="rowValues.id">
                      <p class="font-medium" v-if="rowValues.field">{{ rowValues.field.title }}</p>
                      <p v-if="rowValues.field && rowValues.field.type.name !== 'upload'">{{ rowValues[rowValues.field.type.field] }}</p>
                      <span v-if="rowValues.field.type.name === 'upload' && parsedValue !== undefined">
                        <a class="text-secondary hover:underline focus:underline" :href="`${accountStorageURI}/forms/submissions/${submissionData.id}/${JSON.parse(rowValues[rowValues.field.type.field]).name}`" target="_blank" v-show="parsedValue !== undefined">
                          {{JSON.parse(rowValues[rowValues.field.type.field]).name }}
                          <i class="fas fa-file-download icon-replacement-fix-relative ml-1"/>
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </span>
            <span v-else>
              {{ parsedValue }}
            </span>
          </div>
        </template>
        <span class="truncate text-gray-400" v-else>
          Geen antwoord
        </span>
        <span v-show="showEditButton" class="ml-4 flex-shrink-0">
          <router-link
            custom
            :to="{
              name: 'ControlPanel.Forms.Single.Section',
              params: { id: props.formId, section: props.sectionId },
              query: { ...route.query, question: props.id }
            }"
            v-slot="{ href, navigate }"
          >
            <a class="text-right font-medium text-secondary hover:text-secondary-dark transition-colors"
              :href="href"
              @click="navigate"
              aria-current="section">
              Bewerken
            </a>
          </router-link>
        </span>
      </dd>
    </div>
    <div
      class="rounded-md mt-2 col-span-6 bg-red-50 border border-red-100 px-4 py-2.5"
      v-show="props.errors && props.errors.length">
      <div class="flex" v-for="(error, errorIndex) in props.errors" :key="errorIndex">
        <div class="flex-shrink-0">
          <i class="fas fa-circle-exclamation text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 flex justify-between items-center">
          <p class="text-sm text-red-700">
            {{ error }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
