<template>
  <p :class="[
    'mx-3 text-xs text-gray-600 w-full text-center lg:text-left lg:w-auto mt-3 lg:mt-0 transition-colors',
    { '!text-black underline': timeSinceLastSave === '1 seconden' }
  ]" v-if="timeSinceLastSave">
    {{ timeSinceLastSave }} geleden opgeslagen
  </p>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Forms.LastSavedTimer',

  emits: ['update:lastSaved'],

  props: {
    lastSaved: {
      type: [null,moment],
      default: null
    },
  },

  data() {
    return {
      timeSinceLastSaveInterval: null,
      timeSinceLastSave: null,
    }
  },

  created() {
    moment.locale('nl')
    moment.relativeTimeThreshold('ss', 0)
  },

  watch: {
    lastSaved(newValue, oldValue) {
      if (oldValue === null) {
        this.init()
      }
    }
  },

  methods: {
    /**
     * Set of clears the time interval (every 1 sec) of the last save
     *
     * @return {Void}
     */
    init() {
      clearInterval(this.timeSinceLastSaveInterval)
      this.$emit('update:lastSaved', moment())
      this.timeSinceLastSaveInterval = setInterval(() => {
        this.timeSinceLastSave = moment(this.lastSaved).fromNow(true)
      }, 1000)
    },
  }
}
</script>
