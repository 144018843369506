<template>
  <div :class="[
    { 'space-y-0.5': !rows.length },
    { 'space-y-2': rows.length },
  ]">
    <div class="w-full rounded-md shadow-sm mt-1">
      <div
        class="border border-t-0 first:border-t first:rounded-t-md last:rounded-b-md bg-gray-50 overflow-hidden"
        v-for="(row, rowIndex) in rows" :key="rowIndex">
        <h2 class="group">
          <div class="flex gap-3 items-center w-full p-4">
            <div @click="toggleRow(row)" :class="[
              'grow-0 bg-white border rounded-full w-[26px] min-w-[26px] h-[26px] min-h-[26px] text-xs inline-flex',
              'justify-center items-center text-center cursor-pointer'
            ]">
              <span class="relative -top-[1px] text-gray-600 pointer-events-none select-none">{{ rowIndex + 1 }}</span>
            </div>
            <p class="grow font-medium text-left text-secondary cursor-pointer" @click="toggleRow(row)">{{ row.name || row.label }}</p>
            <div class="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity px-1.5" @click="toggleRow(row)">
              <i class="fas fa-down icon-replacement-fix-relative text-gray-600" v-show="!row.visible"/>
              <i class="fas fa-up icon-replacement-fix-relative text-gray-600" v-show="row.visible"/>
            </div>
            <div class="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity px-1.5" @click="removeRow(rowIndex)">
              <i class="far fa-trash icon-replacement-fix-relative text-red-500"/>
            </div>
          </div>
        </h2>
        <div :class="[
          'border-t bg-white',
          { 'hidden': !row.visible }
        ]">
          <div class="px-3 py-2.5 space-y-3">
            <div v-for="(question, questionIndex) in row.questions" :key="question.id" v-show="question.visible">
              <Field
                :rowIndex="rowIndex"
                :label="question.title"
                :description="question.description"
                :required="!!question.required"
                :errors="question.errors ? question.errors : []">
                <component :is="getComponentByType(question.type.name)"
                  :required="!!question.required && question.visible ? true : false"
                  :id="question.id"
                  :name="question.id"
                  :rowIndex="rowIndex"
                  v-model:value="question.value"
                  :submissionId="hasSubmission ? submissionId : null"
                  :submissionData="hasSubmission ? submissionData : {}"
                  :options="question.options"
                  :dynamic_options="question.dynamic_options"
                  :min="getRestrictionValueByTypeName('min', question.restrictions)"
                  :max="getRestrictionValueByTypeName('max', question.restrictions)"
                  :maxLength="getRestrictionValueByTypeName('max_length', question.restrictions)"
                  :placeholder="question.placeholder"
                  :default="question.default_value"
                  :questions="question.questions"
                  :hasError="!!(question.errors && question.errors.length)"
                  autocomplete="off"
                  @change="async (val) => {
                    question = await inputChanged(question, rowIndex, val)
                    this.$emit('update:submissionData', submissionData)
                    if (this.labelQuestionIndices.includes(questionIndex)) {
                      this.setRowName(rowIndex)
                    }
                  }"
                />
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="[
        'border-2 border-dashed rounded px-4 py-3 flex items-center justify-center select-none cursor-pointer group',
        'hover:border-secondary transition-colors'
      ]"
      @click="addRow(questions)">
      <p class="text-gray-400 group-hover:text-secondary transition-colors">
        Nieuwe rij
        <i class="fas fa-plus icon-replacement-fix-relative ml-2"/>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Field from '@/components/forms/Field'
import InputText from '@/components/forms/fields/InputText'
import InputNumber from '@/components/forms/fields/InputNumber'
import InputBoolean from '@/components/forms/fields/Boolean'
import InputCheckbox from '@/components/forms/fields/Checkbox'
import InputRadio from '@/components/forms/fields/Radio'
import InputDateTime from '@/components/forms/fields/InputDateTime'
import InputDate from '@/components/forms/fields/InputDate'
import InputTime from '@/components/forms/fields/InputTime'
import InputFile from '@/components/forms/fields/InputFile'
import InputTextarea from '@/components/forms/fields/Textarea'
import InputSelect from '@/components/forms/fields/Select'
import InputTel from '@/components/forms/fields/InputTel'
import InputUrl from '@/components/forms/fields/InputUrl'
import Location from '@/components/forms/fields/Location'
import QR from '@/components/forms/fields/QR'

export default {
  name: 'Repeater', // Must match the component name exactly (not path)

  inject: ['axios'],

  emits: [
    'change',
    'update:value',
    'inputChanged',
    'update:submissionData',
    'update:waitForSaving',
  ],

  components: {
    Field,
    InputText,
    InputNumber,
    InputBoolean,
    InputCheckbox,
    InputRadio,
    InputDateTime,
    InputDate,
    InputTime,
    InputFile,
    InputTextarea,
    InputSelect,
    InputTel,
    InputUrl,
    Location,
    QR
  },

  props: {
    id: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: [String,Number],
      default: ''
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default () {
        return []
      }
    },
    submissionData: {
      type: Object,
      default() {
        return {}
      }
    },
    formId: {
      type: Number,
      default: null
    },
    type: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      changed: false,
      rows: []
    }
  },

  computed: {
    labelQuestionIndices(){
      let arr = [];
      for(let i = 0; i < this.questions.length; i++){
        if(this.questions[i].is_label){
          arr.push(i)
        }
      }
      return arr
    },
    hasSubmission() {
      return !!Object.keys(this.submissionData).length
    },

    submissionId() {
      return this.submissionData.id
    },

    repeaterAnswers() {
      if (!this.hasSubmission) return []
      let repeaterId = this.id
      return this.value.filter((answer) => {
        return answer.field.parent_id === repeaterId
      })
    },

    isProjectForm() {
      return this.$route.query.project && !isNaN(this.$route.query.project) ? true : false
    },

    projectId() {
      return this.isProjectForm ? parseInt(this.$route.query.project) : null
    }
  },

  mounted() {
    if (this.repeaterAnswers !== null && this.repeaterAnswers.length) {
      this.fillRowQuestions(this.repeaterAnswers)
    }

    if (this.$route.query.question && !isNaN(this.$route.query.question)
      && this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }
    for(let i = 0; i < this.rows.length; i++){
      this.setRowName(i)
    }
  },

  methods: {
    toggleRow(row) {
      row.visible = !row.visible
    },

    addRow(questions, preserveAnswers = false, visible = true) {
      var qs = []
      for (let i = 0; i < questions.length; i++) {
        let question = Object.assign({}, questions[i])
        qs.push(question)
      }

      // Empty the question values
      qs.forEach((question) => {
        if(!preserveAnswers) question.answer = null
        if(!preserveAnswers) question.value = null
      })

      this.rows.push({
        name: '',
        visible: visible,
        questions: qs
      })
      this.rows[this.rows.length - 1].name = this.setRowName(this.rows.length - 1)
    },

    setRowName(rowIndex){
      if(!this.rows.length || !this.labelQuestionIndices.length){
        this.rows[rowIndex].label = `Rij ${(rowIndex + 1)}`
        return
      }
      const row = this.rows[rowIndex]
      let labels = []
      for(let i = 0; i < this.labelQuestionIndices.length; i++){
        const index = this.labelQuestionIndices[i]
        const question = row.questions[index];
        if(this.isAnswerFilled(question.value)){
          let value
          if(!question.options.length){
            value = question.value
          }else{
            value = this.getOptionTags(question)
          }
          labels.push(value)
        }
      }
      if(labels.length){
        this.rows[rowIndex].name = labels.join(" - ")
        return
      }
      this.rows[rowIndex].name = null
      this.rows[rowIndex].label = `Rij ${(rowIndex + 1)}`
      return
    },
    isAnswerFilled(answer){
      if(answer === null || answer === undefined || !answer.length ){
        return false
      }
      return true
    },

    async removeRow(rowIndex) {
      await this.deleteAnswer(`v1/surveys/answers`, {
        submission_id: this.submissionData.id,
        parent_id: this.id,
        row: rowIndex
      })
      this.rows.splice(rowIndex, 1)
    },

    getComponentByType(name) {
      let componentName = ''
      switch (name) {
        case 'number':
          componentName = 'InputNumber'
          break;
        case 'bool':
          componentName = 'InputBoolean'
          break;
        case 'checkbox':
          componentName = 'InputCheckbox'
          break;
        case 'radio':
          componentName = 'InputRadio'
          break;
        case 'datetime':
          componentName = 'InputDateTime'
          break;
        case 'date':
          componentName = 'InputDate'
          break;
        case 'time':
          componentName = 'InputTime'
          break;
        case 'upload':
          componentName = 'InputFile'
          break;
        case 'textarea':
          componentName = 'InputTextarea'
          break;
        case 'select':
          componentName = 'InputSelect'
          break;
        case 'tel':
          componentName = 'InputTel'
          break;
        case 'url':
          componentName = 'InputUrl'
          break;
        case 'location':
          componentName = 'Location'
          break;
        case 'QR':
          componentName = 'QR'
          break;
        case 'repeater':
          componentName = 'Repeater'
          break;

        default:
          componentName = 'InputText'
          break;
      }
      return componentName
    },
    getOptionTags(question){
      let tags = []
      let values = question.value.split(',')
      for(let i = 0; i < values.length; i++){
        const option = question.options.filter(option => values[i] == option.option_value)
        if(option.length){
          tags.push(option[0].option_name)
        }
      }
      return tags.join(' - ')
    },
    getRestrictionValueByTypeName(name, restrictions) {
      let relatedRestriction = restrictions.filter((restriction) => restriction.type.name === name)
      if (relatedRestriction.length) {
        return relatedRestriction[0][relatedRestriction[0].type.check_field]
      }
    },

    /**
     * Process event when form input changes. It also creates a form submission if not already exists.
     *
     * @param {Object} question
     * @param {Number} rowIndex Index of repeater row
     * @param {Mixed} value
     */
    async inputChanged(question, rowIndex, value) {
      if (this.isFinalized) return
      if (question.conditions && question.conditions.length) {
        this.$emit('inputChanged', question.conditions)
      }

      let store = true
      let remove = false
      this.lastSaved = moment()
      let submissionData = this.submissionData
      if (!this.hasSubmission) {
        submissionData = await this.storeSubmission(`v1/surveys/submissions`)
        if (submissionData !== false) {
          await this.$emit('update:submissionData', submissionData)
        }
      }

      if (question.type.name === 'upload') {
        if (value !== null && typeof value.name === 'string') {
          value = {
            name: value.name,
            size: value.size,
            type: value.type,
            baseUri: `${process.env.VUE_APP_MS_BLOB_STORAGE_URL}/forms/submissions/${this.submissionData.id}`
          }
        } else {
          store = false
          remove = true
        }
      } else if (question.type.name === 'datetime') {
        value = moment(value).format('YYYY-MM-DD HH:mm')
      }

      if (store) {
        try {
          let storedAnswer = null
          if (question.answer && !!Object.keys(question.answer).length) {
            storedAnswer = await this.updateAnswer(`v1/surveys/answers/${question.answer.id}`, {
              submission_id: submissionData.id,
              value: value,
              question_id: question.id,
              row: rowIndex
            })
            let valueCopy = JSON.parse(JSON.stringify(this.value))
            let correspondingIndex = valueCopy.findIndex(answer => answer.id === question.answer.id)
            valueCopy[correspondingIndex] = storedAnswer
            this.$emit('update:value', valueCopy)
          } else {
            storedAnswer = await this.storeAnswer(`v1/surveys/answers`, {
              submission_id: submissionData.id,
              question_id: question.id,
              value: value,
              row: rowIndex
            })
            this.$emit('update:value', [storedAnswer].concat(this.value))
          }
          if(question.type.name == 'upload'){
            storedAnswer = JSON.parse(storedAnswer)
          }
          question.answer = storedAnswer
        } catch (error) {
          question.errors = error
        }
      }

      if (remove) {
        try {
          await this.deleteAnswer(`v1/surveys/answers/${question.answer.id}`)
          question.answer = null
        } catch (error) {
          question.errors = error
        }
      }

      question.value = value
      this.$emit('update:waitForSaving', false)
      return question
    },

    /**
     * Stores a new form submission
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async storeSubmission(endpointURI, customBody = {}) {
      let body = Object.assign({
        form_id: this.formId
      }, customBody)

      if (this.isProjectForm) {
        body.project_id = this.projectId
      }

      try {
        var request = await this.axios.post(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'ControlPanel.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'ControlPanel.Unauthorized'
          })
        } else {
          return Promise.reject(false)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Stores the answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async storeAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.post(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'ControlPanel.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'ControlPanel.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Updates an answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async updateAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.put(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'ControlPanel.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'ControlPanel.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Deletes an answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async deleteAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.delete(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'ControlPanel.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'ControlPanel.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(request.data)
    },

    async fillRowQuestions(answers) {
      const answersSortedByRow = this.groupAnswersByRow(answers, 'row_id')
      for (let a = 0; a < answersSortedByRow.length; a++) {
        let rowAnswers = answersSortedByRow[a]
        let questions = JSON.parse(JSON.stringify(this.questions))
        questions = this.fillQuestionAnswers(questions, rowAnswers.values)
        this.addRow(questions, true, false)
      }
    },

    groupAnswersByRow(xs, key) {
      let groupedArray = xs.reduce((rv, x) => {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({
              key: v,
              values: [x]
          })
        }
        return rv
      }, [])

      // Sort array of objects by object key
      groupedArray.sort((a, b) => {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
      })

      return groupedArray
    },

    fillQuestionAnswers(questions, answers) {
      for (let b = 0; b < questions.length; b++) {
        const question = questions[b]

        let relatedAnswer = answers.filter((answer) => answer.field_id === question.id)
        if (relatedAnswer && relatedAnswer.length) {
          relatedAnswer = relatedAnswer[0]
          let relatedAnswerValue = relatedAnswer[question.type.field]
          question.answer = relatedAnswer
          if (relatedAnswerValue === null) {
            continue
          }
          switch (question.type.field) {
            case 'numeric_value':
              question.value = parseFloat(relatedAnswerValue)
              break;
            case 'datetime_value':
              question.value = moment(relatedAnswerValue).format(moment.HTML5_FMT.DATETIME_LOCAL)
              break;

            default:
              question.value = relatedAnswerValue.toString()
              break;
          }

          if (question.type.name === 'upload') {
            question.value = JSON.parse(question.value)
          }
          if (question.type.name === 'time') {
            question.value = moment(question.value, [moment.ISO_8601, moment.HTML5_FMT.TIME]).format(moment.HTML5_FMT.TIME)
          }
        } else {
          question.value = null
        }
      }
      return questions
    },

    getRowLabel(rowIndex) {
      if(!this.labelQuestionIndices.length){
        return `Rij ${(rowIndex + 1)}`
      }
      let answers = []
      for(let i = 0; i < this.labelQuestionIndices.length; i++){
        const index = this.labelQuestionIndices[i]
        const answer = this.rows[rowIndex].questions[index].answer
        if(answer !== undefined && answer !== null && answer != ''){
          answers.push(answer)
        }
      }
      if(answers.length){
        return answers.join('- ')
      }
      return `Rij ${(rowIndex + 1)}`
    },

    getRepeaterAnswers(rowIndex) {
      if (!this.hasSubmission) return []
      let repeaterId = this.id
      return this.value.filter((answer) => {
        return answer.row_id === rowIndex && answer.field.parent_id === repeaterId
      })
    }
  }
}
</script>
