<template>
  <div class="h-16">
    <div
      class="transform rounded-xl relative bg-white p-2 shadow ring-1 ring-black ring-opacity-5 transition-all z-10"
      @update:modelValue="onSelect">
      <div class="relative">
        <div class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 flex items-center justify-center">
          <i class="far fa-search text-gray-500 icon-replacement-fix-relative" aria-hidden="true" />
        </div>

        <input
          ref="button"
          as="text"
          :required="required"
          :value="searchQuery"
          :placeholder="placeholder"
          @input="(e) => search(e.target.value)"
          autocomplete="off"
          :class="[
            'w-full rounded-md border-0 bg-gray-100 pl-12 pr-4 py-2.5 text-gray-900 placeholder-gray-500',
            'focus:ring-0 h-12 ring-0 ring-opacity-0 focus:border-secondary focus:outline-none focus-visible:ring-0 leading-0',
            { 'border-gray-300' : !editing },
            { 'border-red-500 text-red-500' : hasError },
            { 'bg-gray-100 pointer-events-none' : disabled && !editing }
          ]"
        />
      </div>

      <ul
        ref="listboxOptions"
        static
        class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
        v-show="isDropdownVisible">
        <li
          class="py-2 px-2 hover:bg-gray-50 transition-colors rounded cursor-pointer"
          v-for="option in options"
          :key="option[resultTitleColumn]"
          :value="option"
          @click="select(option)">
          <p
            class="select-none text-gray-700 font-medium"
            v-html="highlightSearchQueryInText(getOptionValue(resultTitleColumn, option))"
          />
          <p
            class="select-none text-gray-500"
            v-html="highlightSearchQueryInText(getOptionValue(resultDescriptionColumn, option))"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'AutocompleteSearch',

  inject: ['axios'],

  emits: ['select', 'update:value'],

  props: {
    endpointURI: String,
    endpointParams: {
      type: Object,
      default: function() {
        return {}
      }
    },
    resultTitleColumn: Object,
    resultDescriptionColumn: Object,
    responseDataPath: {
      type: String,
      default: 'data',
    },
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    initialSearchQuery: String,
    placeholder: String,
    editing: Boolean,
    disabled: Boolean,
    required: Boolean,
    hasError: Boolean
  },

  data() {
    return {
      isDropdownVisible: false,
      options: [],
      searchQuery: '',
      page: 1,
      totalPages: 1,
    }
  },

  mounted() {
    const list = this.$refs.listboxOptions
    list.addEventListener('scroll', () => this.checkIfReachedTheEndOfScroll(list))
    document.addEventListener('mousedown', (e) => this.closeListbox(e))
   if (this.initialSearchQuery && this.initialSearchQuery.length) {
      this.searchQuery = this.initialSearchQuery
    } else if (this.value !== null && Object.keys(this.value).length) {
      if (this.resultTitleColumn.includes('.')) {
        this.searchQuery = this.getOptionValue(this.resultTitleColumn, this.value)
      } else {
        this.searchQuery = this.value[this.resultTitleColumn]
      }
    }
  },

  methods: {
    search(searchQuery) {
      // Search changed
      if (this.searchQuery !== searchQuery) {
        this.isDropdownVisible = false
        this.$emit('update:value', null)
        this.searchQuery = searchQuery
        this.options = []
        this.page = 1
        this.totalPages = 1
      }

      // Search cleared
      if (!searchQuery.length) {
        this.isDropdownVisible = false
        this.$emit('update:value', null)
        this.options = []
        this.page = 1
        this.totalPages = 1
        return
      }

      // Reached last page
      if (this.page > this.totalPages) {
        return
      }

      this.getPaginatedSearch(this.searchQuery)
    },

    /**
     * Handles the infinite scroll on the listbox
     *
     * @return {Void}
     */
    getMoreSearchOptions: debounce(function() {
      // Reached last page
      if (this.page > this.totalPages) {
        return
      }

      this.getPaginatedSearch(this.searchQuery)
    }, 50),

    /**
     * Handles the paginated requests
     *
     * @param {String} searchQuery
     * @return {Void}
     */
    getPaginatedSearch: debounce(async function(searchQuery) {
      let params = Object.assign({
        search: searchQuery,
        page: this.page,
        perPage: 100,
      }, this.endpointParams)

      try {
        var request = await this.axios.get(this.endpointURI, { params: params })
      } catch (error) {
        //
      }

      if (request !== undefined && request.status >= 200 && request.status < 300) {
        if (this.responseDataPath.length) {
          let deepValue = function(obj, selector){
            let path = selector.split('.')
            for (let i = 0; i < path.length; i++) {
              obj = obj[i]
            }
            return obj
          }

          let deepData = []
          if (this.responseDataPath.includes('.')) {
            deepData = deepValue(request.data, this.responseDataPath)
          } else {
            deepData = request.data[this.responseDataPath]
          }
          deepData.forEach((row) => this.options.unshift(row))
        } else {
          request.data.forEach((row) => this.options.unshift(row))
        }

        if (this.options.length) {
          this.isDropdownVisible = true
        }

        this.page++
        this.totalPages = request.data.last_page
      }
    }, 250),

    /**
     * Sets the selected autocomplete option as the field value
     *
     * @param {Object} option
     * @return {Void}
     */
    select(option) {
      this.$emit('select', option)
      this.$emit('update:value', option)
      this.searchQuery = this.getOptionValue(this.resultTitleColumn, option)
      this.isDropdownVisible = false
    },

    /**
     * Check if the scroll list inside the dropdown
     * reached it's end.
     *
     * @param {Mixed} list
     * @return {Void}
     */
    checkIfReachedTheEndOfScroll(list) {
      if (list.clientHeight !== list.scrollHeight &&
        list.scrollTop + list.clientHeight >= list.scrollHeight
      ) {
        this.getMoreSearchOptions()
      }
    },

    /**
     * Closes the list box on certain events
     *
     * @param {Object} e
     * @return {Void}
     */
    closeListbox(e) {
      if (this.$refs.listbox === undefined || this.$refs.listbox === null) {
        return
      }

      const clickedListButton = e.target === this.$refs.button.el
      const clickedListbox = e.target.parentNode === this.$refs.listboxOptions.el

      if (clickedListButton === false && clickedListbox === false) {
        this.isDropdownVisible = false
      }
    },

    getOptionValue(selectors, option) {
      let combinedValue = ''
      let combineSeparator = ''
      let deep_value = function(obj, selector) {
        let path = selector.split('.')
        for (let i = 0; i < path.length; i++) {
          if (obj[path[i]]) {
            obj = obj[path[i]]
          }
        }
        return obj
      }

      for (let i = 0; i < selectors.length; i++) {
        const selectorProps = selectors[i]
        combinedValue = combinedValue + combineSeparator

        if (selectorProps.label) {
          combinedValue = combinedValue + selectorProps.label + ' '
        }
        if (selectorProps.node.includes('.')) {
          combinedValue = combinedValue + deep_value(option, selectorProps.node)
        } else {
          combinedValue = combinedValue + option[selectorProps.node]
        }
        combineSeparator = ', '
      }

      return combinedValue
    },

    highlightSearchQueryInText(text) {
      const regex = new RegExp(this.searchQuery.trim(), 'gi');
      const newText = text.replace(regex, `<mark class="selection">$&</mark>`);
      return newText
    }
  }
}
</script>
