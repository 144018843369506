<template>
  <div class="w-full mt-1">
    <Field
      label="Coördinaten">
      <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-4">
          <InputText
            required
            name="locationLatitude"
            step="any"
            v-bind:value="value"
            :editing="locationLatitude.editing"
            :hasError="!!locationLatitude.errors.length"
            @input="unsetFieldErrors(locationLatitude)"
          />
        </div>
        <div v-if="!this.showLocation" class="sm:col-span-2">
          <button type="button" class="btn-secondary mb-2 w-full h-12" @click.prevent="showMap">Locatie invullen</button>
        </div>
        <div v-if="this.showLocation" class="sm:col-span-2">
          <button type="button" class="btn-secondary mb-2 w-full h-12" @click.prevent="closeMap">Kaart sluiten</button>
        </div>
      </div>
    </Field>
    <div v-show="showLocation">

      <div class="relative overflow-hidden">
        <div class="flex">
          <AutocompleteSearch
            endpointURI="v1/services/maps/search"
            responseDataPath="results"
            :resultTitleColumn="[{ node: 'address.freeformAddress' }]"
            :resultDescriptionColumn="[
              { node: 'address.localName' },
              { node: 'address.countrySubdivision' },
              { node: 'address.country' }
            ]"
            placeholder="Zoek op adres..."
            @select="selectAddress"
            class="absolute inset-x-4 top-0 shadow z-20 mt-4 mr-20 rounded-xl"
          />
          <button type="button" class="btn-secondary h-16 w-16 absolute right-4 top-0 z-20 mt-4" @click.prevent="setCurrentLocation">
            <i class="fa-solid fa-location-crosshairs text-3xl"></i>
          </button>
        </div>
        <div ref="map" class="inset-0 w-full !cursor-crosshair z-10 h-[400px] rounded-md"></div>

        <div :class="[
            'inset-0 opacity-70 fixed z-20 bg-white',
            { 'hidden' : !isLoading }
          ]">
        </div>

      </div>

    <!-- <button @click.prevent="closeMap" type="button" class="btn-secondary w-full mt-4">Kaart sluiten</button> -->
    </div>
  </div>
</template>

<script>
import InputText from '@/components/crud/fields/InputText'
import Field from '@/components/forms/Field'
import AutocompleteSearch from '@/components/AutocompleteSearch'
import * as L from "leaflet"
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'Forms.Fields.Location',

  emits: ['change', 'update:value', 'update:errors'],

  components: {
    InputText,
    Field,
    AutocompleteSearch
  },

  props: {
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: [String,Number],
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    default: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      changed: false,
      showLocation: false,
      isLoading: true,
      isSaving: false,
      editing: false,
      map: {},
      mapMarker: {},
      locationAddress: {
        type: 'object',
        value: {},
        errors: [],
        editing: false,
        prefill: true
      },
      locationLatitude: {
        type: 'string',
        value: null,
        errors: [],
        editing: false,
        prefill: true
      },
      locationLongitude: {
        type: 'string',
        value: null,
        errors: [],
        editing: false,
        prefill: true
      },
      locationNotes: {
        type: 'text',
        value: '',
        errors: [],
        editing: false,
        prefill: true
      },
    }
  },

  mounted() {
    if (this.default !== null && this.value === null) {
      this.$emit('update:value', this.default)
      this.$emit('change', this.default)
    }
    if (this.$route.query.question && !isNaN(this.$route.query.question)
      && this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }
  },

  methods: {
    showMap() {
      this.showLocation = true
      this.init()
    },

    closeMap() {
      this.showLocation = false
    },

    async init() {
      this.isLoading = false
      if(this.value !== null && this.value !== ""){
        let coordinates = this.value.split(',')
        this.locationLatitude.value = coordinates[0]
        this.locationLongitude.value = coordinates[1]
      }
      setTimeout(() => this.renderMap(), 100)
    },

    async renderMap() {
      if(Object.keys(this.map).length){
        this.map.remove()
      }
      this.map = L.map(this.$refs.map, {
        attributionControl: false,
        zoomControl: false
      })

      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        maxZoom: 21,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN
      }).addTo(this.map)

      if (this.locationLatitude.value !== null && this.locationLongitude.value !== null) {
        this.mapMarker = L.marker([this.locationLatitude.value, this.locationLongitude.value])
        this.map.addLayer(this.mapMarker)
        this.map.setView([this.locationLatitude.value, this.locationLongitude.value], 17)
      } else {
        this.map.setView([52.092876, 5.104480], 7)
      }

      this.map.on('click', (e) => this.clickMapHandler(e))
    },

    clickMapHandler(e) {
      this.setCoordinates(e.latlng.lat, e.latlng.lng)
    },

    async setCoordinates(latitude, longitude) {
      this.map.removeLayer(this.mapMarker)
      this.locationLatitude.value = latitude
      this.locationLongitude.value = longitude
      this.mapMarker = L.marker([latitude, longitude])
      this.map.addLayer(this.mapMarker)
      this.$emit('update:value', this.locationLatitude.value + ', ' + this.locationLongitude.value)
      this.$emit('change', this.locationLatitude.value + ', ' + this.locationLongitude.value)
    },

    selectAddress(data) {
      let zoomLevel = 20
      if (data !== null) {
        if (data.type == 'Geography') {
          zoomLevel = 14
        }
        this.map.setView([data.position.lat, data.position.lon], zoomLevel)
        this.locationLatitude.value = data.position.lat
        this.locationLongitude.value = data.position.lon
        this.map.removeLayer(this.mapMarker)
        this.mapMarker = L.marker([data.position.lat, data.position.lon])
        this.map.addLayer(this.mapMarker)
        this.$emit('update:value', this.locationLatitude.value + ', ' + this.locationLongitude.value)
        this.$emit('change', this.locationLatitude.value + ', ' + this.locationLongitude.value)
      }
    },

    setCurrentLocation() {
      // this.isLoading = true
      if (navigator.geolocation) {
        let vm = this
        navigator.geolocation.getCurrentPosition(
          function(position) {
            vm.setCoordinates(position.coords.latitude, position.coords.longitude)
            vm.map.setView([position.coords.latitude, position.coords.longitude], 17)
            // vm.isLoading = false
          },
          function (e) {
            if (e.code === 1) {
              // vm.errorSettingCurrentLocation('Het is niet mogelijk om je huidige locatie op te halen omdat je browser geen rechten heeft tot de geolocatie. Geef de browser toegang naar de locatievoorzieningen van je toestel en probeer opnieuw.')
            } else {
              // vm.errorSettingCurrentLocation('Het is niet mogelijk om je huidige locatie op te halen. Probeer het later opnieuw.')
            }
            // vm.isLoading = false
        })
      } else {
        // this.errorSettingCurrentLocation('Het is niet mogelijk om je huidige locatie op te halen. Probeer het later opnieuw.')
        // this.isLoading = false
      }
    }
  }
}
</script>
