<template>
  <div class="flex items-center mt-1">
    <div>
      <div class="inline-flex rounded-l-md group" v-for="(option, optionIndex) in options" :key="optionIndex">
        <input
          @change="inputChanged"
          v-model="modelValue"
          :value="parseOptionValue(option.option_value)"
          class="peer hidden"
          type="radio"
          :name="name"
          :id="`${name}-${option.option_value}-${rowIndex}`"
        />
        <label :for="`${name}-${option.option_value}-${rowIndex}`" class="boolean-default boolean-checked-secondary">
          {{ option.option_name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forms.Fields.Boolean',

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    rowIndex: {
      type: [Number, null],
      default: null
    }
  },

  data() {
    return {
      changed: false,
      modelValue: null
    }
  },

  computed: {
    hasPrepend() {
      return this.prepend && this.prepend.length
    },
    hasAppend() {
      return this.append && this.append.length
    },
  },

  created() {
    // Create an independent debounce instance for each time this component is created
    this.save = function (e) {
      let value = e.target.value
      if (value !== undefined && value !== null) {
        if (!e.target.checkValidity()) {
          this.$emit('update:errors', [e.target.validationMessage])
        } else {
          this.$emit('update:errors', [])
        }
        this.$emit('change', value)
      }
    }
    this.modelValue = this.value
  },

  mounted() {
    if (this.$route.query.question && !isNaN(this.$route.query.question) &&
      this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }
  },

  methods: {
    inputChanged(e) {
      let value = this.parseOptionValue(e.target.value)
      this.changed = value !== this.value
      if (this.changed) {
        this.$emit('update:value', value)
        this.save(e)
      }
    },

    save() {
      // Placeholder method to be filled in creation of this method
    },

    parseOptionValue(optionValue) {
      let value = null
      switch (optionValue) {
        case '1':
        case '0':
          value = parseInt(optionValue)
          break;

        case 'true':
        case 'false':
          value = (optionValue === 'true')
          break;

        default:
          value = optionValue
          break;
      }

      return value
    }
  }
}
</script>
