<template>
  <div class="w-full mt-1">
    <label
      :for="name"
      v-if="!uploadProgressStarted && !uploadCompleted"
      class="relative cursor-pointer bg-white rounded-md font-medium flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed hover:border-secondary transition-colors">
      <div class="space-y-1 text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div class="flex text-sm text-gray-600">
            <span class="text-secondary hover:underline">Upload één bestand</span>
            <input
              :name="name"
              :id="name"
              :autocomplete="autocomplete"
              :required="required"
              @change="inputChanged"
              @drop="inputChanged"
              type="file"
              class="absolute top-0 left-0 right-0 z-10 bottom-0 w-full block opacity-0 cursor-pointer"
            />
          <p class="pl-1 hidden sm:block">of sleep en zet hier neer</p>
        </div>
      </div>
    </label>

    <div class="bg-white shadow-sm rounded-md p-4 border" v-show="uploadProgressStarted || uploadCompleted">
      <div class="lg:flex">
        <div class="mb-4 flex-shrink-0 lg:mb-0 lg:mr-4 flex items-center justify-center">
          <div>
            <img class="w-full max-w-xs lg:w-28 rounded-md" :src="filePreview" alt="" v-show="isFileImage" />
            <div class="w-28 h-28 bg-gray-200 flex items-center justify-center rounded-md" v-if="!isFileImage">
              <i class="far fa-file text-3xl"/>
            </div>
          </div>
        </div>
        <div class="flex-grow" v-show="!uploadCompleted">
          <h4 class="sr-only">Status</h4>
          <p class="text-sm text-gray-700">
            <span class="font-medium truncate">{{ fileName }}</span>
            <span v-show="!uploadProgressDone"> bezig met uploaden...</span>
            <span v-show="uploadProgressDone"> is succesvol geüpload</span>
          </p>
          <p class="text-sm text-gray-500">
            <span class="italic truncate">{{ fileSizeToBytes }} </span>
          </p>
          <div class="mt-3" aria-hidden="true">
            <div class="bg-gray-200 rounded-full overflow-hidden">
              <div :class="[
                'h-2 rounded-full transition-all',
                { 'bg-secondary' : !uploadProgressDone },
                { 'bg-primary' : uploadProgressDone }
              ]" :style="`width: ${uploadProgressPercentage}%`"  />
            </div>
            <div class="text-sm font-medium text-gray-600 mt-3">
              <div class="text-right">{{ uploadProgressPercentage }}%</div>
            </div>
          </div>
        </div>

        <div class="flex-grow" v-show="uploadCompleted">
          <p class="text-sm text-gray-700">
            <span class="font-medium">{{ fileName }}</span>
          </p>
          <p class="text-sm text-gray-500">
            <span class="italic truncate">{{ fileSizeToBytes }} </span>
          </p>
          <div class="mt-3" aria-hidden="true">
            <button type="button" class="btn-danger" @click="removeFile">Verwijder</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'

export default {
  name: 'Forms.Fields.InputFile',

  inject: ['axios'],

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    name: {
      type: [String,Number],
      default: ''
    },
    value: {
      type: [null,Object],
      default: function () {
        return {}
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    submissionId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      changed: false,
      file: null,
      isExternalSource: false,
      uploadProgressStarted: false,
      uploadProgressDone: false,
      uploadProgressLoaded: 0,
      uploadProgressTotal: 0,
      uploadCompleted: false,
      waitForSubmissionCallback: false,
    }
  },

  computed: {
    hasPrepend() {
      return this.prepend && this.prepend.length
    },
    hasAppend() {
      return this.append && this.append.length
    },
    isFileImage() {
      if (this.file === null || !Object.keys(this.file).length) {
        return false
      }
      return this.file.type.includes('image')
    },
    fileName() {
      return this.file !== null ? this.file.originalName : ''
    },
    filePreview() {
      if (this.file === null) {
        return ''
      }
      if (this.isExternalSource) {
        return `${process.env.VUE_APP_MS_BLOB_STORAGE_URL}/forms/submissions/${this.submissionId}/${this.file.name}`
      }
      if (this.isFileImage) {
        return URL.createObjectURL(this.file)
      }
      return ''
    },
    fileSizeToBytes() {
      if (this.file === null) {
        return 'Bestandsgrootte onbekend'
      }

      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (this.file.size == 0) return '0 Byte'
      let i = parseInt(Math.floor(Math.log(this.file.size) / Math.log(1024)))
      return Math.round(this.file.size / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    uploadProgressPercentage() {
      if (this.uploadProgressTotal > 0) {
        return Math.round((this.uploadProgressLoaded / this.uploadProgressTotal) * 100)
      }
      return 0
    },
    hasSubmission () {
      return this.submissionId !== null
    }
  },

  watch: {
    async submissionId(newValue, oldValue) {
      if (oldValue === null && this.waitForSubmissionCallback) {
        await this.uploadFile(this.file)
      }
    }
  },

  created() {
    // Create an independent debounce instance for each time this component is created
    this.save = async function(e, file) {
      this.file = file
      if (!e.target.checkValidity()) {
        this.$emit('update:errors', [e.target.validationMessage])
      } else {
        this.$emit('update:errors', [])
      }

      // Save original name before formatting
      const orgName = file.name
      // Generate a unique filename and add the extension of the original file
      const fileName = `${file.name.split('.').shift()}-${Date.now()}-${this.randomString(4)}.${file.name.split('.').pop()}`
      // Adjust the file name of the original file
      const newFile = new File([file], fileName, {
        type: file.type
      });
      // Add original name als property to file
      newFile.originalName = orgName

      this.file = newFile

      if (this.hasSubmission) {
        file = await this.uploadFile(this.file)
      } else {
        this.waitForSubmissionCallback = true
      }
      this.$emit('update:value', this.file)
      this.$emit('change', this.file)
    }

    if (this.value !== undefined && this.value !== null && Object.keys(this.value).length) {
      this.isExternalSource = true
      this.uploadCompleted = true
      this.file = this.value
    }
  },

  mounted() {
    if (this.$route.query.question && !isNaN(this.$route.query.question)
      && this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }
  },

  methods: {
    inputChanged(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      if (files[0].type.includes('image')) {
        const vm = this
        new Compressor(files[0], {
          quality: 0.6,
          maxHeight: 1800,
          success(result) {
            vm.save(e, result)
          },
        })
      } else {
        this.save(e, files[0])
      }
    },

    save() {
      // Placeholder method to be filled in creation of this method
    },

    /**
     * Generate a random string
     *
     * @param length
     * @returns {string}
     */
    randomString(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },

    /**
     * Uploads the File to remote storage container
     *
     * @param {File} file
     * @return {Promise}
     */
    async uploadFile(file) {
      const requestConfig = {
        onUploadProgress: progressEvent => this.trackUploadProgress(progressEvent),
        withCredentials: false,
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-version': '2021-04-10',
          'x-ms-blob-content-disposition': 'attachment'
        }
      }

      try {
        this.uploadProgressStarted = true
        await this.axios.put(
          `${process.env.VUE_APP_MS_BLOB_STORAGE_URL}/forms/submissions/${this.submissionId}/${file.name}?${process.env.VUE_APP_MS_BLOB_STORAGE_SAS}`,
          file,
          requestConfig
        )
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'ControlPanel.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'ControlPanel.Unauthorized'
          })
        } else {
          return Promise.reject(error.response.data)
        }
      }

      this.uploadProgressDone = true
      setTimeout(() => this.completeUpload(), 1000)
      return Promise.resolve(file)
    },

    /**
     * Handle the upload completed event
     *
     * @return {Void}
     */
    completeUpload() {
      this.uploadCompleted = true
      this.uploadProgressStarted = false
      this.uploadProgressDone = false
      this.uploadProgressLoaded = 0
      this.uploadProgressTotal = 0
    },

    /**
     * Resets all props to default
     *
     * @return {Void}
     */
    removeFile() {
      this.$emit('change', null)
      this.uploadCompleted = false
      this.uploadProgressStarted = false
      this.uploadProgressDone = false
      this.uploadProgressLoaded = 0
      this.uploadProgressTotal = 0
      this.file = null
    },

    /**
     * Updates progression props with progress event
     *
     * @param {Object} progressEvent
     * @return {Void}
     */
    trackUploadProgress(progressEvent) {
      this.uploadProgressLoaded = progressEvent.loaded
      this.uploadProgressTotal = progressEvent.total
    }
  }
}
</script>
