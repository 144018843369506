<template>
  <div class="w-full shadow-sm rounded-md mt-1">
    <select
      :name="name"
      :id="name"
      :required="required"
      @change="inputChanged"
      v-model="modelValue"
      :class="[
        'input-default',
        { 'border-red-500 focus:ring-red-600 focus:border-red-600' : hasError },
        { 'border-gray-300 focus:ring-secondary focus:border-secondary' : !hasError }
      ]"
    >
      <option selected :disabled="required" value="">
        {{ placeholder && placeholder.length ? placeholder : 'Selecteer een optie...' }}
      </option>
      <option :value="option.option_value" v-for="(option, optionIndex) in question_options" :key="optionIndex">
        {{ option.option_name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Forms.Fields.Select',

  emits: ['change', 'update:value', 'update:errors'],

  props: {
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    dynamic_options: {
      type: Array,
      default: function () {
        return []
      }
    },
  },

  data() {
    return {
      changed: false,
      modelValue: [],
      question_options: []
    }
  },

  created() {
    // Create an independent debounce instance for each time this component is created
    this.save = function (e) {
      let value = this.modelValue
      if (value !== undefined && value !== null) {
        if (!e.target.checkValidity()) {
          this.$emit('update:errors', [e.target.validationMessage])
        } else {
          this.$emit('update:errors', [])
        }
        this.$emit('change', value)
      }
    }
    this.modelValue = this.value
  },

  mounted() {
    if (this.$route.query.question && !isNaN(this.$route.query.question) &&
      this.$refs.input !== undefined && parseInt(this.$route.query.question) === this.name) {
      this.$refs.input.reportValidity()
      this.$refs.input.focus()
    }

    if(this.dynamic_options === null) {
      this.question_options = this.options
    }

    if (this.dynamic_options !== null) {
      for(var i = 0; i < this.dynamic_options.options.length; i++){
        this.question_options.push({
          option_value: this.dynamic_options.options[i].Waarde,
          option_name: this.dynamic_options.options[i].Waarde
        })
      }
    }
  },

  methods: {
    inputChanged(e) {
      let value = this.modelValue
      this.changed = value !== this.value
      if (this.changed) {
        this.$emit('update:value', value)
        this.save(e)
      }
    },

    save() {
      // Placeholder method to be filled in creation of this method
    },
  }
}
</script>
